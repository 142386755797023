<template>
  <div
    class="absolute z-30 top-5 left-5 bg-white right-5 bottom-5 grid grid-cols-2 overflow-y-auto overflow-x-hidden"
    style="width: 500px; grid-template-rows: auto auto auto 1fr; height: 95vh"
  >
    <div>
      <div class="bg-white mx-auto" style="width: 500px" @scroll="handleScroll">
        <div
          class="cont h-28 bg-white w-full z-50 inset-0 pt-3 px-8 transition-all ease-out duration-500 sticky"
          style="width: 100%"
        >
          <div class="flex justify-between items-center pb-4 mt-4">
            <div class="font-bold text-darkblue text-xl">
              {{ $t('routesTrips.route.addFromGeozones.title') }}
            </div>
            <i
              class="el-icon-close text-2xl cursor-pointer text-darkblue font-semibold"
              @click="$emit('close')"
            ></i>
          </div>
          <div
            class="flex justify-end items-center bg-white mb-6"
            :class="scrolled ? 'shadow' : ''"
          >
            <skif-button
              class="mr-2 w-full float-right"
              variant="normal"
              @click="$emit('close')"
              >{{ $t('btn.cancel') }}</skif-button
            >
            <skif-button
              class="w-full float-right"
              @click="
                addGeozone()
                $emit('my-done')
              "
              >{{ $t('add') }}
            </skif-button>
          </div>
          <div class="bg-white border border-t-0 border-l-0 border-r-0 pb-3 flex justify-start items-center pb-4 mt-4">
            <skif-search
              v-model="filterString"
              :placeholder="$t('search')"
              style="width: 450px; height: 35px"
              @searching="searching"
            ></skif-search>
          </div>
        </div>
        <table style="width: 480px; margin-top: 100px; margin-left: 20px">
          <thead>
            <tr>
              <th class="bg-white z-10 w-10 top-0">
                <skif-checkbox-old
                  class="mr-3"
                  @click.native="(event) => event.stopPropagation()"
                  v-model="isAllGeozonesChecked"
                  :indeterminate="isIndeterminate"
                ></skif-checkbox-old>
              </th>
              <th class="bg-white fontLato top-0 text-left" style="width: 220px">
                <span
                  class="text-darkblue fz-6 leading-10 cursor-pointer mr-2"
                  style="font-size: 16px"
                >
                  Название
                </span>
              </th>
              <th class="bg-white fontLato top-0" style="width: 80px"></th>
            </tr>
          </thead>
          <tbody
            move-class="transition duration-1000"
            style="border-top: 4px solid white"
          >
            <tr
              class="cursor-pointer hover_bg-hoverrow"
              v-for="route in filteredGeozone"
            >
              <td class="w-2 pl-3">
                <skif-checkbox-old
                  class="pl-1"
                  type="checkbox"
                  v-model="geozonesSelected"
                  :label="route.id"
                ></skif-checkbox-old>
              </td>
              <td class="ellipsis align-left">
                <span class="leading-10 nameTable">{{ route.name }}</span>
              </td>
              <td class="pr-8 align-right"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      isMainShowen: true,
      isIndeterminate: false,
      geozonesSelected: [],
      isRoutes: true,
      scrolled: false,
      filterString: '',
      isInArray: false,
      selectedUsers: []
    }
  },
  computed: {
    ...mapState('geozones', {
      geozones: (state) => state.geozones
    }),
    isAllGeozonesChecked: {
      get() {
        return this.geozones
          ? this.geozonesSelected.length === this.geozones.length
          : false
      },
      set(value) {
        const selected = []

        if (value) {
          this.geozones.forEach(function (geozone) {
            selected.push(geozone.id)
          })
        }

        this.geozonesSelected = selected
      }
    },

    filteredGeozone() {
      return this.geozones.filter((geozone) => {
        return geozone.name.toLowerCase().match(this.filterString.toLowerCase())
      })
    }
  },
  methods: {
    searching(val) {
      this.filterString = val
    },
    drawGeo(geo) {
      console.log('draw geo', geo)
      this.$store.commit('routes/draw_geo', geo)
    },
    addGeozone() {
      const component = this
      let selectedGeozones = []
      selectedGeozones = this.geozones.filter((el) =>
        this.geozonesSelected.some((id) => el.id === id)
      )
      selectedGeozones.forEach((geozone) => this.drawGeo(geozone))
      this.$store
        .dispatch('routes/ADD_FROM_EXISTING_GEOZONE', selectedGeozones)
        .then((_) => component.$emit('close'))
    },
    deleteGeozones() {
      // console.log('Start deleting Routes...')
    },

    handleScroll(a) {
      this.scrolled = a.target.scrollTop > 0
    }
  }
}
</script>
<style scoped>
.buttons {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #91b4e7;
  mix-blend-mode: normal;
}

.buttons:focus {
  outline: none;
}

.isActive {
  color: #3c5e8f;
  font-size: 18px;
  transition: all 0.3s ease-in-out;
}

.fade-enter-active {
  transition: opacity 0.5s ease-in-out;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
